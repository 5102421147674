<template>
	<div class="flame-container">
		<div class="red flame"></div>
		<div class="orange flame"></div>
		<div class="yellow flame"></div>
		<div class="white flame"></div>
	</div>
</template>

<script>
export default {
	name: 'FlameAnimated',
}
</script>

<style scoped lang="scss">
@use 'sass:math';
$size: 2.8125rem;
.flame-container {
	margin: 0 auto;
	width: $size;
	height: $size;
	position: relative;
	transform-origin: center bottom;
	animation-name: flicker;
	animation-duration: 3ms;
	animation-delay: 200ms;
	animation-timing-function: ease-in;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

.flame {
	bottom: 0;
	position: absolute;
	border-bottom-right-radius: 50%;
	border-bottom-left-radius: 50%;
	border-top-left-radius: 50%;
	transform: rotate(-45deg) scale(1.5, 1.5);
}

.yellow {
	left: calc($size * 0.25);
	width: calc($size * 0.5);
	height: calc($size * 0.5);
	background: gold;
	box-shadow: 0px 0px calc($size * 0.15) calc($size * 0.0666) gold;
}

.orange {
	left: calc($size * 0.16666);
	width: calc($size * 0.666);
	height: calc($size * 0.666);
	background: orange;
	box-shadow: 0px 0px calc($size * 0.15) calc($size * 0.0666) orange;
}

.red {
	left: calc($size * 0.08333);
	width: calc($size * 0.8333);
	height: calc($size * 0.8333);
	background: OrangeRed;
	box-shadow: 0px 0px calc($size * 0.08333) calc($size * 0.0666) OrangeRed;
}

.white {
	left: calc($size * 0.25);
	bottom: calc($size * 0.0666 * -1);
	width: calc($size * 0.5);
	height: calc($size * 0.5);
	background: white;
	box-shadow: 0px 0px calc($size * 0.15) calc($size * 0.0666) white;
}

@keyframes flicker {
	0% {
		transform: rotate(-1deg);
	}
	20% {
		transform: rotate(1deg);
	}
	40% {
		transform: rotate(-1deg);
	}
	60% {
		transform: rotate(1deg) scaleY(1.04);
	}
	80% {
		transform: rotate(-2deg) scaleY(0.92);
	}
	100% {
		transform: rotate(1deg);
	}
}
</style>
