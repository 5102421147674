<template>
	<div class="landing-yt" @click="onPageClick">
		<div class="landing-yt__container">
			<div class="landing-yt__header">
				<img src="@/assets/logos/logo-dark.svg" alt="Simple Logo" />
			</div>
		</div>
		<div class="landing-yt__section">
			<div class="landing-yt__container">
				<h1 class="landing-yt__main-title">
					Say goodbye
					<br />
					to crash diets
				</h1>
				<h2 class="landing-yt__section-subtitle landing-yt__mb-3">
					and hello to sustainable
					<br />
					weight loss with SIMPLE
				</h2>
			</div>
			<div class="landing-yt__video-container" ref="videoContainer">
				<div id="player"></div>
				<button class="landing-yt__unmute-video" v-if="playing && muted">
					<img src="@/assets/land-yt/unmute.svg" alt="unmite video icon" @click="unmuteVideo" />
				</button>
				<button class="landing-yt__play-video" v-if="!playing">
					<img src="@/assets/land-yt/play.svg" alt="unmite video icon" @click="playVideo" />
				</button>
			</div>
		</div>
		<transition
			enter-class="custom-appear-class"
			enter-to-class="custom-appear-to-class"
			enter-active-class="custom-appear-active-class"
		>
			<div v-show="!landHidden">
				<div class="landing-yt__section" ref="secondSection">
					<div class="landing-yt__container">
						<h2 class="landing-yt__section-title landing-yt__mb-3">
							Discover the power
							<br />
							of intermittent fasting
						</h2>
						<a
							href="#"
							class="landing-yt__button landing-yt__button-primary pump-animation"
							@click.prevent="toSurvey('video')"
						>
							Start fasting with SIMPLE
						</a>
					</div>
				</div>
				<div class="landing-yt__section landing-yt__section-well-done">
					<div class="landing-yt__container">
						<div class="landing-yt__winner-block landing-yt__mb-3">
							<div class="landing-yt__winner-item">
								<div class="landing-yt__winner-item-content">
									<div class="landing-yt__winner-item-top">
										OVER
										<br />
										130 000
									</div>
									<div class="landing-yt__winner-item-bottm">5 star reviews</div>
								</div>
							</div>
							<div class="landing-yt__winner-item">
								<div class="landing-yt__winner-item-content">
									<div class="landing-yt__winner-item-top">
										APP OF
										<br />
										THE DAY
									</div>
									<div class="landing-yt__winner-item-bottm">2O22</div>
								</div>
							</div>
						</div>
					</div>
					<div class="landing-yt__extra-large-title landing-yt__mb-3">
						<div class="landing-yt__extra-large-title-top">
							<span>~</span>
							<span>3.9M lb.</span>
						</div>
						<div class="landing-yt__extra-large-title-bottom">lost by SIMPLE users in 2022</div>
					</div>
					<div class="landing-yt__container">
						<img src="@/assets/land-yt/well-done.png" alt="well done" class="landing-yt__well-done" />
					</div>
				</div>
			</div>
		</transition>
		<div v-show="!landHidden">
			<div class="landing-yt__section landing-yt__section-graph">
				<div class="landing-yt__container">
					<div class="landing-yt__section-title landing-yt__mb-3">
						Find out when you could
						<br />
						reach your goal weight
					</div>
					<div class="landing-yt__card landing-yt__card-graph landing-yt__mb-3">
						<img src="@/assets/land-yt/graph.svg" alt="weight graph" />
					</div>
					<div class="landing-yt__subtitle landing-yt__mb-3">
						Adapted to you and without
						<br />
						excluding any type of foods
					</div>
					<a
						href="#"
						class="landing-yt__button landing-yt__button-primary pump-animation"
						@click.prevent="toSurvey('well-done')"
					>
						Start fasting with SIMPLE
					</a>
				</div>
			</div>
			<div class="landing-yt__section landing-yt__section-reviews">
				<div class="landing-yt__container">
					<div class="landing-yt__section-title landing-yt__mb-2">Leave diets behind</div>
					<div class="landing-yt__subtitle landing-yt__mb-3">
						and transform your body with small
						<br />
						changes, consistent actions, and
						<br />
						the power of intermittent fasting
					</div>
					<div class="landing-yt__reviews">
						<div class="landing-yt__card landing-yt__card-review">
							<div class="landing-yt__reviews-image">
								<img src="@/assets/land-yt/review-1.png" alt="review 1 image" />
							</div>
							<div class="landing-yt__reviews-bottom">
								<div class="landing-yt__reviews-text">
									<div class="landing-yt__reviews-desc">I’m down from 240 to 164 lb.</div>
									<div class="landing-yt__reviews-location">Debi, US</div>
								</div>
								<div class="landing-yt__reviews-right">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 82 83">
										<path
											stroke="url(#a)"
											stroke-linecap="round"
											stroke-width="6.6"
											d="M7.3 59a37.7 37.7 0 1 1 67.3.2"
										/>
										<defs>
											<linearGradient id="a" x1="73.5" x2="9.5" y1="64.5" y2="64.5" gradientUnits="userSpaceOnUse">
												<stop stop-color="#F2C44C" />
												<stop offset="1" stop-color="#FF7A00" />
											</linearGradient>
										</defs>
									</svg>
									<div class="landing-yt__reviews-right-number">
										<span>-76</span>
										<span>lb.</span>
									</div>
								</div>
							</div>
						</div>
						<div class="landing-yt__card landing-yt__card-review">
							<div class="landing-yt__reviews-image">
								<img src="@/assets/land-yt/review-2.png" alt="review 1 image" />
							</div>
							<div class="landing-yt__reviews-bottom">
								<div class="landing-yt__reviews-text">
									<div class="landing-yt__reviews-desc">
										I’ve lost 10 lb.
										<br />
										And My clothes are loose.
									</div>
									<div class="landing-yt__reviews-location">Teresa, US</div>
								</div>
								<div class="landing-yt__reviews-right">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 82 83">
										<path
											stroke="url(#a)"
											stroke-linecap="round"
											stroke-width="6.6"
											d="M7.3 59a37.7 37.7 0 1 1 67.3.2"
										/>
										<defs>
											<linearGradient id="a" x1="73.5" x2="9.5" y1="64.5" y2="64.5" gradientUnits="userSpaceOnUse">
												<stop stop-color="#F2C44C" />
												<stop offset="1" stop-color="#FF7A00" />
											</linearGradient>
										</defs>
									</svg>
									<div class="landing-yt__reviews-right-number">
										<span>-10</span>
										<span>lb.</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="landing-yt__section landing-yt__section-app-1">
				<div class="landing-yt__container">
					<div class="landing-yt__section-title">Here is what</div>
					<div class="landing-yt__section-big-title landing-yt__mb-4">you get</div>
					<img src="@/assets/land-yt/app-1.png" alt="app image" class="landing-yt__app-image-1" />
				</div>
			</div>
			<div class="landing-yt__section landing-yt__section-app-2">
				<div class="landing-yt__container">
					<div class="landing-yt__section-title landing-yt__mb-2">All the fasting schedules & support you need</div>
					<div class="landing-yt__section-app-2-dots-container landing-yt__mb-2">
						<div class="landing-yt__dots-block"></div>
					</div>
					<div class="landing-yt__timers landing-yt__mb-4">
						<div class="landing-yt__timers-item">
							<div class="landing-yt__timers-item-content">12:12</div>
						</div>
						<div class="landing-yt__timers-item">
							<div class="landing-yt__timers-item-content">18:6</div>
						</div>
						<div class="landing-yt__timers-item">
							<div class="landing-yt__timers-item-content">20:4</div>
						</div>
						<div class="landing-yt__timers-item">
							<div class="landing-yt__timers-item-content">16:8</div>
						</div>
						<div class="landing-yt__timers-item">
							<div class="landing-yt__timers-item-content">14:10</div>
						</div>
					</div>
					<div class="landing-yt__app-image-2-container landing-yt__mb-3">
						<img src="@/assets/land-yt/app-2-1.png" alt="app image" class="landing-yt__app-image-2-1" />
						<img src="@/assets/land-yt/app-2-2.png" alt="app image" class="landing-yt__app-image-2-2" />
					</div>
					<div class="landing-yt__subtitle">
						Find the fasting schedule you like and stick
						<br />
						with it, or extend your fasts as you succeed
					</div>
				</div>
			</div>
			<div class="landing-yt__section">
				<div class="landing-yt__container">
					<div class="landing-yt__section-title landing-yt__mb-0-5">
						Personalized steps as the&nbsp;app learns about you
					</div>
					<div class="landing-yt__numbered-blocks landing-yt__mb-4">
						<div class="landing-yt__numbered-blocks-item">
							<div class="landing-yt__numbered-blocks-item-content">Insights tailored to&nbsp;your needs</div>
						</div>
						<div class="landing-yt__numbered-blocks-item">
							<div class="landing-yt__numbered-blocks-item-content">
								Daily tasks to&nbsp;help you make healthier choices
							</div>
						</div>
						<div class="landing-yt__numbered-blocks-item">
							<div class="landing-yt__numbered-blocks-item-content">Tips for&nbsp;your meals</div>
						</div>
					</div>
					<div class="landing-yt__section-title landing-yt__mb-2">
						Watch your body
						<br />
						go to&nbsp;work
					</div>
					<div class="landing-yt__subtitle landing-yt__mb-3">See when you’re burning fat as you fast</div>
					<div class="landing-yt__burn-timer-block">
						<img class="landing-yt__burn-timer-timer-image" src="@/assets/land-yt/burn-timer.png" alt="burn timer" />
						<div class="landing-yt__burn-timer-fire">
							<FlameAnimated />
						</div>
					</div>
				</div>
			</div>
			<div class="landing-yt__section landing-yt__section-receipe">
				<div class="landing-yt__container">
					<div class="landing-yt__section-title landing-yt__mb-2">Get inspired</div>
					<div class="landing-yt__subtitle landing-yt__mb-3">
						with recipes to keep your meals fresh and your taste buds happy
					</div>
					<img class="landing-yt__receipe-image" src="@/assets/land-yt/receipe.png" alt="receipe image" />
				</div>
			</div>
			<div class="landing-yt__section landing-yt__section-resources">
				<div class="landing-yt__container">
					<div class="landing-yt__section-resources-title landing-yt__mb-3">
						Other SIMPLE
						<br />
						resources
					</div>
					<div class="landing-yt__resources">
						<div class="landing-yt__resources-item landing-yt__mb-3">
							<div class="landing-yt__resources-item-title">Your guide to&nbsp;awesome eating habits</div>
							<div class="landing-yt__resources-item-image-container">
								<img
									class="landing-yt__resources-item-image"
									src="@/assets/land-yt/eating-guide.png"
									alt="eating guide"
								/>
							</div>
							<div class="landing-yt__dots-block"></div>
							<div class="landing-yt__resources-item-improvements">
								<div class="landing-yt__resources-item-improvement">Improve your meals & snacks</div>
								<div class="landing-yt__resources-item-improvement">Become an epic meal planner</div>
								<div class="landing-yt__resources-item-improvement">See better results on the scale</div>
							</div>
						</div>
						<div class="landing-yt__resources-item">
							<div class="landing-yt__resources-item-title">Your build-your-own-workout plan</div>
							<div class="landing-yt__resources-item-image-container">
								<img
									class="landing-yt__resources-item-image"
									src="@/assets/land-yt/workout-guide.png"
									alt="workout guide"
								/>
							</div>
							<div class="landing-yt__dots-block"></div>
							<div class="landing-yt__resources-item-improvements">
								<div class="landing-yt__resources-item-improvement">Maximize using fitness for fat loss</div>
								<div class="landing-yt__resources-item-improvement">Find the exercises that suit you</div>
								<div class="landing-yt__resources-item-improvement">Develop that routine you always wanted</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="landing-yt__section">
				<div class="landing-yt__container">
					<div class="landing-yt__section-title landing-yt__mb-1">Money-Back Guarantee</div>
					<div class="landing-yt__subtitle landing-yt__mb-3">
						Get visible results
						<br />
						or&nbsp;a&nbsp;full refund (T&Cs apply)
						<div>
							<a class="landing-yt__link" :href="legalLinks.refund" target="_blank">Learn more</a>
						</div>
					</div>
					<img class="landing-yt__mb-3" src="@/assets/land-yt/moneyback.png" alt="moneyback guarantee" />
					<a
						href="#"
						class="landing-yt__button landing-yt__button-skeleton pump-animation"
						@click.prevent="toSurvey('resources')"
					>
						Start fasting with SIMPLE
					</a>
				</div>
			</div>
			<div class="landing-yt__section landing-yt__section-app-3">
				<div class="landing-yt__container">
					<div class="landing-yt__section-app-3-top-title">Start the journey to a healthier,</div>
					<div class="landing-yt__section-app-3-bottom-title landing-yt__mb-3">happier you</div>
				</div>
				<img
					class="landing-yt__section-app-3-image landing-yt__mb-3"
					src="@/assets/land-yt/app-3.png"
					alt="app preview"
				/>
				<div class="landing-yt__container">
					<div class="landing-yt__winner-block landing-yt__mb-3">
						<div class="landing-yt__winner-item">
							<div class="landing-yt__winner-item-content">
								<div class="landing-yt__winner-item-top">
									OVER
									<br />
									130 000
								</div>
								<div class="landing-yt__winner-item-bottm">5 star reviews</div>
							</div>
						</div>
						<div class="landing-yt__winner-item">
							<div class="landing-yt__winner-item-content">
								<div class="landing-yt__winner-item-top">
									APP OF
									<br />
									THE DAY
								</div>
								<div class="landing-yt__winner-item-bottm">2O22</div>
							</div>
						</div>
					</div>
					<a
						href="#"
						class="landing-yt__button landing-yt__button-primary pump-animation"
						@click.prevent="toSurvey('section-app')"
					>
						Start fasting with SIMPLE
					</a>
				</div>
			</div>
			<div class="landing-yt__footer">
				<div class="landing-yt__container">
					<div class="landing-yt__social-block landing-yt__mb-3">
						<div class="landing-yt__social-item">
							<a
								href="https://www.instagram.com/simplefastingapp/"
								target="_blank"
								class="landing-yt__social-item-icon"
							>
								<img src="@/assets/land-yt/socials/insta.svg" alt="instagram icon" />
							</a>
						</div>
						<div class="landing-yt__social-item">
							<a href="https://www.facebook.com/simplefastingapp" class="landing-yt__social-item-icon" target="_blank">
								<img src="@/assets/land-yt/socials/fb.svg" alt="facebook icon" />
							</a>
						</div>
						<div class="landing-yt__social-item">
							<a href="https://www.tiktok.com/@simpleappofficial" class="landing-yt__social-item-icon" target="_blank">
								<img src="@/assets/land-yt/socials/ttq.svg" alt="tiktok icon" />
							</a>
						</div>
						<div class="landing-yt__social-item">
							<a href="https://twitter.com/simplefasting?lang=en" class="landing-yt__social-item-icon" target="_blank">
								<img src="@/assets/land-yt/socials/tw.svg" alt="twitter icon" />
							</a>
						</div>
						<div class="landing-yt__social-item">
							<a
								href="https://www.youtube.com/channel/UC1N4JCEJODBfVLFFgfm8Q9Q"
								class="landing-yt__social-item-icon"
								target="_blank"
							>
								<img src="@/assets/land-yt/socials/yt.svg" alt="youtube icon" />
							</a>
						</div>
						<div class="landing-yt__social-item">
							<a
								href="https://www.pinterest.com/simplefastingapp/"
								class="landing-yt__social-item-icon"
								target="_blank"
							>
								<img src="@/assets/land-yt/socials/pin.svg" alt="pinterest icon" />
							</a>
						</div>
					</div>
					<div class="landing-yt__footer-logo">
						<img src="@/assets/land-yt/logo-inline.svg" alt="" />
					</div>
					<div class="landing-yt__footer-note landing-yt__mb-3">
						Simple Intermittent Fasting & Meal Tracker: AI-powered personal health insights, cutting-edge analytical
						tools, and daily educational articles to keep you motivated.
					</div>
					<div class="landing-yt__footer-address landing-yt__mb-2">
						Made by AM APPS LTD Office 902, Oval, Krinou 3, Ayios Athanasios, 4103, Limassol, Cyprus
					</div>
					<div class="landing-yt__footer-links">
						<a :href="legalLinks.tos" target="_blank" class="landing-yt__footer-link">Terms & Conditions</a>
						<a :href="legalLinks.privacy" target="_blank" class="landing-yt__footer-link">Privacy Policy</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FlameAnimated from '@/components/landing/youtube/FlameAnimated'
import { mapGetters } from 'vuex'
import VimeoPlayer from '@vimeo/player'
import anime from 'animejs'

export default {
	name: 'LandingYT',
	components: { FlameAnimated },
	data() {
		return {
			muted: true,
			landHidden: true,
			playing: false,
		}
	},
	watch: {
		landHidden(val) {
			if (!val) {
				this.$nextTick(this.animateScroll)
			}
		},
	},
	created() {
		document.documentElement.classList.add('landing-yt')
	},
	mounted() {
		this.initVideoPlayer()
	},
	beforeDestroy() {
		document.documentElement.classList.remove('landing-yt')
		this.destroyVideoPlayer()
	},
	computed: {
		...mapGetters({
			legalLinks: 'i18n/getLegalLinks',
		}),
	},
	methods: {
		toSurvey(v) {
			this.$analytic.logEvent('OnboardingWelcomeScreenDone', {
				data: v,
			})
			this.$router.push({ name: 'youtube-survey' })
		},
		unmuteVideo() {
			this.vimeoPlayer.setMuted(false)
			this.muted = false
		},
		playVideo() {
			this.vimeoPlayer.play()
			this.unmuteVideo()
		},
		ontimeUpdate({ seconds }) {
			if (!this.landHidden) return
			if (seconds >= 10) {
				this.landHidden = false
			}
		},
		onPageClick() {
			if (this.landHidden) {
				return
			}
			this.toSurvey('screen')
		},
		animateScroll() {
			const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement
			const needToScroll = Math.round(
				Math.abs(this.$refs.secondSection.getBoundingClientRect().bottom - window.innerHeight) + 75,
			)
			anime({
				targets: scrollElement,
				scrollTop: needToScroll,
				duration: 2000,
				easing: 'easeInOutSine',
			})
		},
		initVideoPlayer() {
			const width = window.innerWidth
			const player = new VimeoPlayer('player', {
				id: 835775333,
				width: width,
				controls: false,
				autoplay: true,
				responsive: true,
				muted: true,
			})
			player.on('playing', () => (this.playing = true))
			player.on('timeupdate', this.ontimeUpdate)
			this.vimeoPlayer = player
		},
		destroyVideoPlayer() {
			this.vimeoPlayer.destroy()
		},
	},
}
</script>

<style scoped lang="scss">
@use 'sass:math';
@import '@/styles/mixins.scss';
@mixin gridArea($gutterH: null, $gutterV: null, $wrap: false, $width: null) {
	display: flex;
	@if ($gutterH != null) {
		margin-left: math.div(-$gutterH, 2);
		margin-right: math.div(-$gutterH, 2);
	}
	@if ($gutterV != null) {
		margin-top: math.div(-$gutterV, 2);
		margin-bottom: math.div(-$gutterV, 2);
	}
	@if ($wrap) {
		flex-wrap: wrap;
	}
	flex-direction: row;
	min-width: 0;

	> * {
		@if ($gutterH != null) {
			padding-left: math.div($gutterH, 2);
			padding-right: math.div($gutterH, 2);
		}
		@if ($gutterV != null) {
			padding-top: math.div($gutterV, 2);
			padding-bottom: math.div($gutterV, 2);
		}
		@if ($width != null) {
			width: $width;
		}
	}
}
@function pxtorem($px) {
	@return math.div($px, 16) * 1rem;
}
$monteserrat: 'Montserrat', sans-serif;
.landing-yt {
	color: #323653;
	overflow-x: hidden;

	&__mb-4 {
		margin-bottom: pxtorem(76) !important;
	}
	&__mb-3 {
		margin-bottom: pxtorem(56) !important;
	}
	&__mb-2 {
		margin-bottom: pxtorem(48) !important;
	}
	&__mb-1 {
		margin-bottom: pxtorem(32) !important;
	}
	&__mb-0-5 {
		margin-bottom: pxtorem(12) !important;
	}
	&__container {
		width: 100%;
		margin: 0 auto;
		max-width: 640px;
		padding: 0 pxtorem(50);
	}
	&__main-title {
		font-size: pxtorem(85);
		line-height: 0.94;
		text-align: center;
		letter-spacing: 0.01em;
		margin-bottom: pxtorem(16);
		font-weight: 900;
	}
	&__section-subtitle {
		margin: 0;
		padding: 0;
		text-align: center;
		font-size: pxtorem(37);
		letter-spacing: 0.01em;
		line-height: 1.07;
		font-weight: 900;
	}
	&__section-title {
		margin: 0;
		padding: 0;
		text-align: center;
		font-size: pxtorem(49);
		letter-spacing: -0.005em;
		line-height: 1.15;
		font-weight: 900;
	}
	&__header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: pxtorem(88);

		img {
			height: pxtorem(48);
		}
	}
	&__section {
		padding: pxtorem(44) 0;
		&.landing-yt__section-well-done {
			background: linear-gradient(0deg, #ffefeb 0%, rgba(254, 239, 238, 0) 53%, rgba(255, 255, 255, 0) 100%);
		}
		&.landing-yt__section-graph {
			background: linear-gradient(180deg, #ffefeb 0%, rgba(254, 239, 238, 0) 90%, rgba(255, 255, 255, 0) 100%);
		}
		&.landing-yt__section-reviews {
			background: linear-gradient(0deg, #ffefeb 0%, rgba(254, 239, 238, 0.5) 80%, rgba(255, 255, 255, 0) 100%);
			padding-bottom: pxtorem(88);
		}
		&.landing-yt__section-app-1 {
			position: relative;
			background: url(@/assets/land-yt/app-image-bg-2.png) calc(50% + #{pxtorem(240)}) 50% no-repeat,
				url(@/assets/land-yt/app-image-bg-3.png) calc(50% - #{pxtorem(245)}) 94% no-repeat,
				radial-gradient(
						circle #{pxtorem(600)},
						rgba(233, 132, 211, 1) 0%,
						rgba(233, 132, 211, 0.265) 68.75%,
						rgba(255, 255, 255, 0) 100%
					)
					calc(50% + #{pxtorem(240)}) 50% no-repeat,
				#7e4de7;
			background-size: pxtorem(608) pxtorem(579), pxtorem(695) pxtorem(695), pxtorem(2000), pxtorem(2000);
			&::after {
				content: '';
				position: absolute;
				bottom: 19.4%;
				right: calc(50% - #{pxtorem(352)});
				z-index: 1;
				background: url(@/assets/land-yt/app-image-bg-1.png) center no-repeat;
				background-size: contain;
				width: pxtorem(237);
				height: pxtorem(237);
			}
			.landing-yt__section-title {
				color: #fff;
			}
			.landing-yt__section-big-title {
				color: #fff;
			}
		}
		&.landing-yt__section-app-2 {
			background: linear-gradient(
				180deg,
				#7d4de6 0%,
				#8853e9 37.84%,
				#b067e9 58.2%,
				#cf91e7 67.61%,
				#e9d1f5 79.43%,
				#f0ecfc 85.12%,
				#ffffff 92.78%
			);
			.landing-yt__section-title {
				color: #fff;
			}
		}
		&.landing-yt__section-receipe {
			background: linear-gradient(180deg, #ffffff 0%, #ffffff 45.31%, #7f4ee8 93.75%);
		}
		&.landing-yt__section-resources {
			background: linear-gradient(180deg, #7e4de7 0%, #6334c9 100%);
		}
		&.landing-yt__section-app-3 {
			background: linear-gradient(180deg, #7a48e9 0%, #8c4ef3 29.17%, #ae83f7 51.56%, #ece1fd 68.9%, #ffffff 79.83%);
		}
	}
	&__video-container {
		min-height: pxtorem(800);
		width: 100%;
		max-width: 640px;
		margin: 0 auto;
		background: #121111;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: none;
			user-select: none;
		}

		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__unmute-video {
		position: absolute;
		top: pxtorem(20);
		right: pxtorem(20);
		background: #fff;
		padding: pxtorem(10);
		border: 0;
		border-radius: 50%;
		width: pxtorem(50);
		height: pxtorem(50);
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		box-shadow: 0 pxtorem(12) pxtorem(21) rgba(0, 0, 0, 0.2);
		z-index: 2;

		img {
			width: pxtorem(30);
		}
	}
	&__play-video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		padding: pxtorem(10);
		border: 0;
		border-radius: 50%;
		width: pxtorem(80);
		height: pxtorem(80);
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		box-shadow: 0 pxtorem(12) pxtorem(21) rgba(0, 0, 0, 0.2);
		z-index: 2;

		img {
			width: pxtorem(50);
		}
	}
	&__button {
		display: block;
		padding: pxtorem(30);
		height: 100%;
		border-radius: pxtorem(25);
		font-weight: 600;
		font-size: pxtorem(35);
		line-height: 1.3;
		text-align: center;
		letter-spacing: -0.02em;
		text-decoration: none;
		transition: transform 255ms;

		@include hasHover {
			&:hover {
				transform: scale(1.05);
			}
		}

		&.landing-yt__button-primary {
			background: radial-gradient(circle at 0 -300%, rgba(216, 132, 255, 1) 0%, rgba(216, 132, 255, 0) 100%),
				radial-gradient(circle at 200%, rgba(121, 75, 220, 1) 0%, rgba(121, 75, 220, 0) 100%), #867edf;
			color: #fff;
			box-shadow: inset -2px -2px 0px rgba(0, 0, 0, 0.2);
		}

		&.landing-yt__button-skeleton {
			color: #8489aa;
			letter-spacing: -0.5px;
			border: 2px solid #8489aa;
		}
	}
	&__winner-block {
		width: 100%;
		@include gridArea(pxtorem(44));
	}
	&__winner-item {
		width: 100%;
	}
	&__winner-item-content {
		padding: 0 pxtorem(35);
		position: relative;
		text-align: center;
		color: #b0b4cd;
		font-weight: 800;
		font-family: $monteserrat;
		text-transform: uppercase;

		&::before,
		&::after {
			content: '';
			top: 0;
			position: absolute;
			width: pxtorem(35);
			height: 100%;
			background: url(@/assets/land-yt/branch.svg) no-repeat;
			background-size: contain;
		}
		&::before {
			left: 0;
		}
		&::after {
			right: 0;
			transform: scaleX(-1);
		}
	}
	&__winner-item-top {
		font-weight: 800;
		font-size: pxtorem(30);
		line-height: 102.5%;
	}
	&__winner-item-bottom {
		color: #fff;
		font-size: pxtorem(17);
		line-height: 110%;
		letter-spacing: -0.035em;
	}
	&__extra-large-title {
		text-align: center;
		font-weight: 900;
	}
	&__extra-large-title-top {
		font-size: pxtorem(136);
		line-height: 89.5%;
		margin-bottom: pxtorem(5);
		letter-spacing: -0.033em;
		color: #323653;
		@supports (-webkit-text-fill-color: transparent) {
			background: radial-gradient(circle farthest-corner at 200% -300%, #ff8899 35%, rgb(50, 54, 83) 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		span:first-child {
			letter-spacing: -0.02em;
			font-size: pxtorem(104);
			line-height: 110%;
		}
	}
	&__extra-large-title-bottom {
		font-size: pxtorem(37);
		line-height: 107%;
		color: #323653;
		letter-spacing: 0.01em;
	}
	&__well-done {
		max-width: calc(100% + #{pxtorem(102)});
		margin-left: calc(#{pxtorem(-102)} / 2);
	}
	&__card {
		border-radius: pxtorem(50);
		background-color: #fff;
		box-shadow: 0 pxtorem(2) pxtorem(2) rgba(238, 225, 220, 0.42), 0 pxtorem(12) pxtorem(21) #f4f2f7;
		&.landing-yt__card-graph {
			padding: pxtorem(40);
		}
		&.landing-yt__card-review {
			padding: pxtorem(30);
			box-shadow: 0 pxtorem(2) pxtorem(2) rgba(238, 225, 220, 0.42), 0 pxtorem(12) pxtorem(21) #eee1dc;
		}
	}
	&__subtitle {
		font-weight: 500;
		font-size: pxtorem(28);
		line-height: 110%;
		text-align: center;
		color: #8489aa;
	}
	&__reviews {
		.landing-yt__card:not(:last-child) {
			margin-bottom: pxtorem(18);
		}
	}
	&__reviews-image {
		width: 100%;
		margin-bottom: pxtorem(30);
	}
	&__reviews-bottom {
		display: flex;
		align-items: center;
	}
	&__reviews-text {
		flex: 1 1 auto;
		margin-right: pxtorem(40);
	}
	&__reviews-right {
		flex: 0 0 auto;
		position: relative;
		svg {
			width: pxtorem(82);
		}
	}
	&__reviews-right-number {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-55%, -50%);
		font-family: $monteserrat;
		font-weight: 700;
		color: #ff7b01;
		text-align: center;
		line-height: 1.25;

		span {
			display: block;
			&:first-child {
				font-size: pxtorem(32);
				letter-spacing: -1.34464px;
			}
			&:last-child {
				font-size: pxtorem(16);
				letter-spacing: -0.479131px;
				margin-top: pxtorem(-8);
			}
		}
	}
	&__reviews-desc {
		font-family: $monteserrat;
		font-weight: 600;
		font-size: pxtorem(24);
		line-height: 110%;
		margin-bottom: pxtorem(4);
	}
	&__reviews-location {
		font-weight: 600;
		font-size: pxtorem(18);
		line-height: 110%;
		letter-spacing: 0.015em;
		text-transform: capitalize;
		color: #646885;
	}
	&__section-big-title {
		font-weight: 900;
		font-size: pxtorem(129);
		line-height: 0.895;
		text-align: center;
	}
	&__app-image-1 {
		filter: drop-shadow(0px pxtorem(13) pxtorem(33) rgba(73, 73, 73, 0.2));
		will-change: transform;
		max-width: pxtorem(417);
		margin: 0 auto;
		display: block;
	}
	&__dots-block {
		height: pxtorem(21);
		width: calc(#{pxtorem(21)} * 5 + #{pxtorem(13)} * 4);
		background: url(@/assets/land-yt/dot.svg) 0 center no-repeat,
			url(@/assets/land-yt/dot.svg) pxtorem(21) + pxtorem(13) center no-repeat,
			url(@/assets/land-yt/dot.svg) calc((pxtorem(21) + pxtorem(13)) * 2) center no-repeat,
			url(@/assets/land-yt/dot.svg) calc((pxtorem(21) + pxtorem(13)) * 3) center no-repeat,
			url(@/assets/land-yt/dot.svg) calc((pxtorem(21) + pxtorem(13)) * 4) center no-repeat;
	}
	&__section-app-2-dots-container {
		.landing-yt__dots-block {
			margin: 0 auto;
		}
	}
	&__timers {
		@include gridArea(pxtorem(16));
		justify-content: center;
		align-items: center;
	}
	&__timers-item-content {
		font-weight: 600;
		font-size: pxtorem(28);
		line-height: 1.1;
		text-align: center;
		color: #ffffff;
		padding: pxtorem(10) pxtorem(16) pxtorem(13);
		border: 2px solid #fff;
		border-radius: pxtorem(30);
	}
	&__app-image-2-container {
		position: relative;
		width: 100%;
		height: pxtorem(903);
	}
	&__app-image-2-1 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		filter: drop-shadow(0px pxtorem(29) pxtorem(73) rgba(227, 227, 227, 0.56));
		will-change: transform;
	}
	&__app-image-2-2 {
		position: absolute;
		top: 20.2%;
		left: 0;
		width: 100%;
		height: auto;
		z-index: 1;
		will-change: transform;
		filter: drop-shadow(0px pxtorem(1) pxtorem(32) rgba(0, 0, 0, 0.12))
			drop-shadow(0px pxtorem(22) pxtorem(38) rgba(157, 160, 177, 0.17));
	}
	&__numbered-blocks {
		@include gridArea(pxtorem(25));
		counter-reset: numbered-block;
	}
	&__numbered-blocks-item {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		counter-increment: numbered-block;
		position: relative;
		height: pxtorem(155);
		width: 33.3%;
		font-weight: 500;
		font-size: pxtorem(23);
		line-height: 1.1;
		letter-spacing: 0.01em;
		color: #8489aa;

		&::before {
			content: '#' counter(numbered-block);
			position: absolute;
			z-index: -1;
			color: rgba(139, 144, 175, 0.1);
			font-weight: 900;
			font-size: pxtorem(145);
			line-height: 1.07;
			letter-spacing: -0.01em;
			text-transform: lowercase;
		}
	}
	&__burn-timer-block {
		position: relative;
		img {
			max-width: calc(100% + #{pxtorem(32)});
			margin-left: pxtorem(-17);
			margin-top: pxtorem(5);
		}
	}
	&__burn-timer-fire-image {
		position: absolute;
		top: 12.5%;
		right: 2%;
		border-radius: 50%;
		width: pxtorem(128);

		backdrop-filter: blur(pxtorem(9));
		box-shadow: 0 pxtorem(4) pxtorem(4) rgba(225, 209, 194, 0.16),
			pxtorem(12) pxtorem(9) pxtorem(37) rgba(231, 206, 187, 0.35);
	}
	&__burn-timer-fire {
		position: absolute;
		top: 36%;
		right: 3.7%;
		border-radius: 50%;
		width: pxtorem(128);
		height: pxtorem(128);
		backdrop-filter: blur(pxtorem(9));
		box-shadow: -2px -1px 0px white, pxtorem(9) pxtorem(7) pxtorem(29) rgba(231, 206, 187, 0.35);
		:deep(.flame-container) {
			margin-top: calc(#{pxtorem(128)} / 3);
		}
	}
	&__receipe-image {
		display: block;
		margin: 0 auto;
		max-width: pxtorem(416);
	}
	&__section-resources-title {
		font-weight: 900;
		font-size: pxtorem(82);
		line-height: 0.895;
		text-align: center;
		letter-spacing: 0.005em;
		color: #fff;
	}
	&__resources {
		counter-reset: resource-block;
	}
	&__resources-item {
		color: #fff;
		counter-increment: resource-block;
		position: relative;

		&::before {
			content: '#' counter(resource-block);
			position: absolute;
			top: 37%;
			right: -18%;
			z-index: 0;
			font-weight: 900;
			font-size: pxtorem(519);
			line-height: 0;
			letter-spacing: -0.01em;
			color: rgba(255, 255, 255, 0.07);
			pointer-events: none;
		}

		.landing-yt__dots-block {
			margin-bottom: pxtorem(38);
		}
	}
	&__resources-item-title {
		font-weight: 900;
		font-size: pxtorem(48);
		line-height: 1.07;
		padding-right: pxtorem(240);
		margin-bottom: pxtorem(25);
	}
	&__resources-item-image-container {
		position: absolute;
		top: 0;
		right: pxtorem(-30);
		height: pxtorem(362);
		width: pxtorem(264);
	}
	&__resources-item-improvement {
		position: relative;
		z-index: 1;
		font-weight: 600;
		font-size: pxtorem(26);
		line-height: 1.1;
		display: inline-block;
		padding: pxtorem(10) pxtorem(25) pxtorem(13);
		background: rgba(132, 84, 235, 0.68);
		border: 2px solid #ffffff;
		box-shadow: pxtorem(1) pxtorem(4) pxtorem(15) rgba(106, 71, 217, 0.38);
		border-radius: pxtorem(30);

		&:not(:last-child) {
			margin-bottom: pxtorem(24);
		}
	}
	&__link {
		text-decoration: none;
		color: #1cabe8;
		border-bottom: 1px solid currentColor;
	}
	&__section-app-3-top-title {
		font-weight: 900;
		font-size: pxtorem(40);
		line-height: 0.985;
		color: #ffffff;
		margin-bottom: pxtorem(20);
		text-align: center;
	}
	&__section-app-3-bottom-title {
		font-weight: 900;
		font-size: pxtorem(84);
		line-height: 0.895;
		color: #ffffff;
		text-align: center;
	}
	&__section-app-3-image {
		max-width: 640px;
		display: block;
		margin: 0 auto;
		width: 100%;
	}
	&__footer {
		padding: pxtorem(44) 0 pxtorem(88) 0;
	}
	&__social-block {
		@include gridArea(pxtorem(25));
		align-items: center;
		justify-content: center;
	}
	&__social-item-icon {
		display: block;
		width: pxtorem(55);
	}
	&__footer-address {
		font-size: pxtorem(20);
		line-height: 1.1;
		text-align: center;
		color: #9194ba;
	}
	&__footer-note {
		font-size: pxtorem(20);
		line-height: 1.1;
		text-align: center;
		color: #9194ba;
	}
	&__footer-logo {
		margin-bottom: pxtorem(53);
		text-align: center;
		img {
			height: pxtorem(48);
		}
	}
	&__footer-links {
		display: flex;
		justify-content: space-between;
		font-size: pxtorem(28);
		line-height: 1.31;
		text-align: center;
		.landing-yt__footer-link {
			font-weight: 700;
			color: #867edf;
		}
	}
}
.pump-animation {
	animation: pump 1s linear infinite;
}
@keyframes pump {
	0% {
		transform: scale(0.97);
	}
	5% {
		transform: scale(1.07);
	}
	39% {
		transform: scale(0.9);
	}
	45% {
		transform: scale(1);
	}
	60% {
		transform: scale(0.97);
	}
	100% {
		transform: scale(0.95);
	}
}

.custom-appear-class {
	transform: translateY(#{pxtorem(-100)});
	opacity: 0;
}
.custom-appear-to-class {
	transform: translateY(0);
	opacity: 1;
}
.custom-appear-active-class {
	transition: all 2000ms;
}
</style>

<style lang="scss">
.landing-yt {
	font-size: clamp(8px, 2.5vw, 16px);
}
</style>
